/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import "./src/global.css";

// not added automatically because gatsby was using core-js@2, which didn't include this polyfill
// TODO: can I remove this?
import "core-js/web/url-search-params";

import browserUpdate from "browser-update/update.npm";
import * as Sentry from "@sentry/browser";
import type { GatsbyBrowser } from "gatsby";

let config = {
  required: { e: -4, f: -3, o: -3, s: -1, c: -3 },
  insecure: true,
  style: "top",
  api: 2020.02,
};
browserUpdate(config);

// https://github.com/octalmage/gatsby-plugin-sentry
export const onClientEntry: GatsbyBrowser["onClientEntry"] = (_, pluginParams) => {
  if (process.env.NODE_ENV === "production") {
    Sentry.init({
      dsn: "https://e90d9b81f46044659a674b8a6347ddfe@sentry.fluidsolar.com.au/16",
      // Optional settings, see https://docs.sentry.io/clients/node/config/#optional-settings
      // environment: process.env.NODE_ENV,
      // enabled: (() => ['production', 'stage'].indexOf(process.env.NODE_ENV) !== -1)()
    });
    window.Sentry = Sentry;
  }
};
